import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import {DATE_FORMAT_DISPLAY_IN_TEXT, TIME_FORMAT_DISPLAY_IN_TEXT} from "../../../constants";
import {sv} from "date-fns/locale";
import Grid from "@mui/material/Unstable_Grid2";


const DeleteForm = ({isOpen, onClose, onDelete, booking, rooms}) => {
    const {t} = useTranslation();

    const buttonStyle = { margin: "0 auto", display: "block" };

    let roomName = ""
    if (booking && rooms.length > 0) {
        roomName = rooms.find(room => room.id === booking.roomId).name;
    }

    return (
        <Dialog open={isOpen} >
            <DialogTitle>{t("booking-page.delete-form.title")}</DialogTitle>
            <DialogContent sx={{minWidth: "250px"}}>
                <Typography variant="body1">
                    {roomName}
                </Typography>
                <Typography variant="body1">
                    {booking && format(booking?.startTime, DATE_FORMAT_DISPLAY_IN_TEXT, {locale: sv})}
                </Typography>
                <Typography variant="body1" component="span">
                    {booking && format(booking?.startTime, TIME_FORMAT_DISPLAY_IN_TEXT, {locale: sv})}
                    -
                    {booking && format(booking?.endTime, TIME_FORMAT_DISPLAY_IN_TEXT, {locale: sv})}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Grid container sx={{width: "100%"}}>
                    <Grid xs={6}>
                        <Button
                            color="error"
                            variant="outlined"
                            onClick={() => onDelete(booking?.id)}
                            sx={buttonStyle}
                        >
                            {t("booking-page.delete-form.delete-button")}
                        </Button>
                    </Grid>
                    <Grid xs={6}>
                        <Button
                            variant="contained"
                            onClick={onClose}
                            sx={buttonStyle}
                        >
                            {t("booking-page.delete-form.cancel-button")}
                        </Button>
                    </Grid>
                </Grid>



            </DialogActions>
        </Dialog>
    )
}

export default DeleteForm;
