import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import Grid from "@mui/material/Unstable_Grid2";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import {DATE_FORMAT_DISPLAY_IN_TEXT} from "../../../constants";
import {format} from "date-fns";
import {useTranslation} from "react-i18next";
import {sv} from "date-fns/locale";


const BookForm = ({
    isOpen,
    selectedRoom,
    selectedDate,
    selectedStartTime,
    onSelectedStartTimeChange,
    selectedEndTime,
    onSelectedEndTimeChange,
    onSubmit,
    onClose,
    errorMessage
}) => {
    const { t } = useTranslation();

    if (selectedStartTime && !selectedEndTime) {
        onSelectedEndTimeChange(selectedStartTime);
    }

    return (
        <Dialog
            open={isOpen}
            PaperProps={{
                component: "form",
                onSubmit: (event) => {
                    event.preventDefault();
                    onSubmit(selectedRoom, selectedStartTime, selectedEndTime);
                }
            }}
        >
            <DialogTitle>{ t("book-form.title", {roomName: selectedRoom?.name})}</DialogTitle>
            <DialogContent>
                <Typography variant="h6">
                    {format(selectedDate, DATE_FORMAT_DISPLAY_IN_TEXT, {locale: sv})}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
                    <Grid container mt={3}>
                        <Grid container xs={12}>
                            <Grid xs={5} display="flex">
                                <TimePicker
                                    label={t("book-form.start-time-label")}
                                    value={selectedStartTime}
                                    onChange={(newValue) => onSelectedStartTimeChange(newValue)}
                                    ampm={false}
                                />
                            </Grid>
                            <Grid xs={2} display="flex">
                                <HorizontalRuleIcon sx={{width: "100%", fontSize: "50px", lineHeight: "56px"}} />
                            </Grid>
                            <Grid xs={5} display="flex">
                                <TimePicker
                                    label={t("book-form.end-time-label")}
                                    value={selectedEndTime}
                                    onChange={(newValue) => onSelectedEndTimeChange(newValue)}
                                    ampm={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
                <DialogActions>
                    <Grid xs={12} mt={3} container justifyContent="center">
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{margin: "0 10px"}}
                        >
                            {t("book-form.submit-button")}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{margin: "0 10px"}}
                            onClick={onClose}
                        >
                            {t("book-form.cancel-button")}
                        </Button>
                    </Grid>
                </DialogActions>
                {errorMessage ? <Typography color="error">{errorMessage}</Typography> : null}
            </DialogContent>
        </Dialog>
    )
}

export default BookForm;
